<template>
  <v-card>
    <v-card-title class="toptitle">
      <span class="text-h3">
        {{ (type == 'Update') ? 'Edit' : 'Create' }} Booking
      </span>
    </v-card-title>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card class="addNewCard">
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="booking.product"
                  :disabled="disabledField"
                  dense
                  :items="['oem', 'cso']"
                  :label="$t('select_product_*')"
                  :rules="[rules.required]"
                  @change="tenantList"
                />
              </v-col>
              <v-col
                v-if="authUser.isAdmin"
                cols=" 12"
                sm="6"
              >
                <v-autocomplete
                  v-model="booking.tenant_id"
                  :disabled="disabledField"
                  dense
                  :items="booking.product
                    ? booking.product == 'oem'
                      ? tenantsOEM
                      : tenantsCSO
                    : []"
                  :label="$t('select_tenant_*')"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  @change="onTenantChange"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="booking.location_id"
                  :disabled="disabledField"
                  dense
                  clearable
                  :items="locationsList"
                  item-value="id"
                  item-text="name"
                  :label="$t('location') + ' *'"
                  :rules="[rules.required]"
                  @change="fetchData"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <select-date
                  v-model="booking.booking_date"
                  :label="$t('select_date_*')"
                  :min="dateValidation('min')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="booking.start_slot_time"
                  :rules="[rules.required]"
                  :items="timeIntervals"
                  label="Select Time *"
                  item-value="id"
                  item-text="name"
                  dense
                />
              </v-col>

              <v-col
                v-if="type == 'Update'"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="booking.booking_status"
                  dense
                  :items="statusList"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
    <div
      v-if="type != 'Update'"
      class="userAssignWrap"
    >
      <v-row class="rowWrap">
        <v-col cols="12">
          <span class="text-h3">{{ $t("Assign Users") }}</span>
          <v-row class="noMargin">
            <v-col cols="6">
              <v-checkbox
                v-model="booking.customerType"
                hide-details
                :ripple="false"
                :label="$t('Existing Users')"
                :value="'existingUser'"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="booking.customerType"
                hide-details
                :ripple="false"
                :label="$t('New User')"
                :value="'newUser'"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-expand-transition>
        <template v-if="booking.customerType == 'newUser'">
          <div>
            <v-form
              ref="customerform"
              lazy-validation
            >
              <v-card class="addNewCard">
                <v-card-text class="nopadding">
                  <v-container class="">
                    <!-- Customer Details -->
                    <span class="text-h3 custom-h3">{{ $t("Customer Detail") }}:</span>
                    <v-row dense>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="customer.customer_name"
                          dense
                          :label="$t('customer_name') + ' *'"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="customer.customer_email"
                          dense
                          :label="$t('email_*')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-select
                          v-model="customer.customer_country_code"
                          dense
                          :items="countryCodeList"
                          item-value="id"
                          item-text="name"
                          :label="$t('country_code_*')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="customer.customer_phone_number"
                          dense
                          :label="$t('phone_number_*')"
                          :rules="[
                            rules.required,
                            rules.min_length(10),
                            rules.max_length(10),
                          ]"
                        />
                      </v-col>
                    </v-row>

                    <!-- Vehicle Details -->
                    <span class="text-h3 custom-h3 bodTop">{{ $t("Vehicle Detail") }}</span>
                    <v-row dense>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-autocomplete
                          v-model="vehicle.vehicle_md_make_id"
                          dense
                          :items="mdMakesList"
                          :loading="isLoadingMdMakes"
                          item-value="id"
                          item-text="name"
                          :label="$t('ev_make_*')"
                          :rules="[rules.required]"
                          @change="onMakeChange"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-select
                          v-model="vehicle.vehicle_md_model_id"
                          dense
                          :items="vehicle.vehicle_md_make_id ? mdModelsList : []"
                          :loading="isLoadingMdModels"
                          item-value="id"
                          item-text="name"
                          :label="$t('ev_model_*')"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="vehicle.vehicle_year"
                          dense
                          type="number"
                          :min="getStartingYear()"
                          :max="getCurrentYear()"
                          :label="$t('year_*')"
                          :rules="[rules.required, rules.min_year(), rules.max_year()]"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="vehicle.vehicle_registration_no"
                          dense
                          :label="$t('registration_no') + '*'"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </div>
        </template>
        <v-row
          v-else-if="booking.customerType == 'existingUser'"
          transition="scroll-y-transition"
        >
          <v-card-text v-if="!customerAdd">
            <div class="searchBoxwithBtn">
              <v-text-field
                v-model="searchQuery"
                :disabled="booking.tenant_id == null"
                label="Search Customer by Phone / Email / Name"
                clearable
                @input="fetchCustomerData"
              />
            </div>

            <v-data-table
              v-if="tableView"
              :loading="getCustomerListLoading"
              :headers="transactionHeader"
              :items="customerList"
              item-value="id"
              class="elevation-1"
              hide-default-footer
              @update:select-all="() => (selectedCustomer = null)"
              @update:items-per-page="(selected) => (selectedCustomer = selected[0])"
            >
              <template v-slot:no-data>
                <span>{{ $t("no_data_available") }}</span>
              </template>

              <template v-slot:item="{ item }">
                <tr
                  style="cursor: pointer"
                  @click="onItemSelect(item)"
                >
                  <td>
                    <input
                      v-model="selectedItem"
                      type="radio"
                      :value="item"
                      @change="onItemSelect(item)"
                    >
                  </td>
                  <td>{{ item.customer_name }}</td>
                  <td>{{ item.customer_email }}</td>
                  <td>{{ item.customer_phone_number }}</td>
                  <td>{{ item.vehicle_make_and_model }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-row>
      </v-expand-transition>
    </div>
    <div
      v-else
      class="userAssignWrap"
    >
      <v-row class="rowWrap">
        <v-col cols="12">
          <span class="text-h3">{{ $t("Assigned Users") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-card-text>
          <v-data-table
            :headers="transactionHeader"
            :items="[formattedBooking]"
            item-value="id"
            class="elevation-1"
            hide-default-footer
            @update:select-all="() => (selectedCustomer = null)"
            @update:items-per-page="(selected) =>
              (selectedCustomer = selected[0])
            "
          >
            <template v-slot:no-data>
              <span>
                {{ $t("no_data_available") }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-row>
    </div>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red darken-1"
        text
        @click="close()"
      >
        Close
      </v-btn>
      <v-btn
        color="primary"
        text
        :loading="isLoadingSave"
        @click="createBooking"
      >
        {{ (type == 'Update') ? 'Save' : 'Create' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  components: {
    // dataTableScroll: () => import("@/components/base/dataTableScroll.vue"),
    selectDate: () => import("@/components/base/SingleDatePicker.vue"),
  },
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return 'Create';
      },
    },
    itemData: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoadingSave: false,
      customer: {},
      existing_customer: {},
      booking: { customerType: 'existingUser' },
      vehicle: {},
      existing_vehicle: {},
      customerAdd: false,
      tableView: false,
      dialog: false,
      searchQuery: "",
      selectedCustomer: null,
      selectedItem: null,
      bookingId: null,
      customerSelectedData: {},
      timeIntervals: this.generateTimeIntervals(),
      customFilters: {},
      statusList: [
        {
          id: "pending_approval",
          name: "PENDING APPROVAL",
        },
        {
          id: "approved",
          name: "APPROVED",
        },
        {
          id: "rejected",
          name: "REJECTED",
        },
        {
          id: "rescheduled",
          name: "RESCHEDULED",
        },
        {
          id: "cancelled",
          name: "CANCELLED",
        },
        {
          id: "completed",
          name: "COMPLETED",
        },
        {
          id: "in_progress",
          name: "IN PROGRESS",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      mdModelsList: "mdModels/activelistMdModels",
      countryCodeList: "getCountryCode",
      searchCustomerData: "csmsChargeStations/getBookingCustomer",
      getCustomerListLoading: "csmsChargeStations/getCustomerListLoading",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsCSO: "tenantsManagement/getTenantsCSO",
      locationsList: "csmsChargeStations/getLocation",
      customerList: "csmsBookings/getCustomerList",
    }),
    formattedBooking() {
      return {
        ...this.booking,
        customer_complete_phone_number: `${this.booking.customer_phone_number}`,
        vehicle_make_and_model: `${this.booking.vehicle_make_name} ${this.booking.vehicle_model_name}`,
      };
    },
    tenantsByProduct() {
      if (this.vehicle?.product) {
        if (this.vehicle.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    transactionHeader() {
      const headers = [
        // { text: 'Select', value: 'id', align: 'start' },
        { text: 'Name', value: 'customer_name' },
        { text: 'Phone', value: 'customer_complete_phone_number' },
        { text: 'Email', value: 'customer_email' },
        { text: 'Vehicle', value: 'vehicle_make_and_model' },
      ];
      if (this.type !== 'Update') {
        headers.unshift({ text: 'Select', value: 'id', align: 'start' });
      }
      return headers;
    },
    disabledField() {
      if (this.type == 'Update') {
        return true;
      }
      return false;
    }
  },

  async mounted() {
    if (this.type == "Create") {
      if (!this.authUser.isAdmin) {
        this.booking.tenant_id = this.authUser.tenant_id
        this.onTenantChange(this.options.tenant_id)
      }
    }

    if (this.type == "Update") {
      this.tableView = true
      this.booking = { ...this.itemData };
      this.booking.tenant_id = parseInt(this.booking.tenant_id)
      this.booking.location_id = parseInt(this.booking.location_id)
      await this.$store.dispatch("csmsChargeStations/LocationsList", {
        tenant_id: this.booking.tenant_id,
      });
    }
  },
  methods: {
    tenantList() {
      if (this.product == "oem") {
        this.tenantOEM = this.tenantsList.filter((r) => r.product == "oem");
      } else {
        this.tenantOEM = this.tenantsList.filter((r) => r.product == "cso");
      }
    },
    async fetchData() {
      const params = { ...this.options, ...this.customFilters };
      await this.$store.dispatch("csmsChargingSession/list", params);
    },
    onItemSelect(item) {
      this.selectedItem = item;
      this.existing_customer = {
        customer_id: item.customer_id,
        customer_name: item.customer_name,
        customer_phone_number: item.customer_phone_number,
        customer_email: item.customer_email,
        customer_country_code: item.customer_country_code,
      }
      this.existing_vehicle = {
        vehicle_id: item.vehicle_id,
        vehicle_md_make_id: item.vehicle_make_id,
        vehicle_md_model_id: item.vehicle_model_id,
        vehicle_registration_no: item.vehicle_registration_no,
        vehicle_year: item.vehicle_year
      }
    },
    addCustomer() {
      this.resetTableView()
      this.customerAdd = true;
      this.tableView = false;
      this.searchQuery = "";
    },
    resetTableView() {
      this.selectedItem = null;
      this.customerSelectedData = null;
      this.bookingId = null;
    },
    resetCustomerView() {
      this.customerAdd = false;
      this.customer = {};
      this.vehicle = {};
    },

    onSearch: debounce(function (v) {
      if (v != null) {
        v = v.trim();
        if (v == "") {
          this.options.search = null;
        }
        this.emitData();
      }
    }, 3000),

    fetchCustomerData: debounce( function() {
      if (this.searchQuery === "" || this.searchQuery.length < 3) {
        return;
      }
      this.resetCustomerView();
      this.resetTableView();
      this.$store.dispatch("csmsBookings/getCustomers", {
        tenant_id: this.booking.tenant_id,
        phone_number: this.searchQuery
      }).then(() => { this.tableView = true });

    },2000),

    async onTenantChange(v) {
      delete this.vehicle.vehicle_md_model_id;
      delete this.vehicle.vehicle_md_make_id;
      const params = { tenant_id: v };
      this.$store.dispatch("mdMakes/list", params);
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    },

    generateTimeIntervals() {
  const intervals = [];
  const startTime = 0; // 00:00
  const endTime = 24 * 60; // 1440 minutes
  const interval =60; // 30-minute interval

  for (let minutes = startTime; minutes < endTime; minutes += interval) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const secs = 0; // Always 0 for consistency

    // 24-hour format (id)
    const id = `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;

    // 12-hour AM/PM format (value)
    const period = hours < 12 ? "AM" : "PM";
    const displayHours = hours % 12 || 12; // 0 -> 12
    const name = `${displayHours}:${String(mins).padStart(2, "0")} ${period}`;

    intervals.push({ id, name });
  }

  return intervals;
},

    async createBooking() {
      let bookingObject = {};
      let url = "csmsBookings/addBooking";
      let msg = this.$t("created_successfully")
      const isValid = this.$refs.form?.validate();
      if (!isValid) {
        return;
      }

      if (this.booking.customerType === 'newUser') {
        const isValidCus = this.$refs.customerform?.validate();
        if (!isValidCus) {
          return;
        }
      }
      if (this.type !== 'Update') {
        {
          if (this.booking.customerType == 'existingUser') {
              if (!this.selectedItem) {
              this.$store.dispatch(
                "alerts/error",
                this.$t("Select Customer")
              );
              return;
            }
          }
        }
          bookingObject = {
          ...this.booking, // Preserve existing booking details
          customer_details: (this.booking.customerType === 'newUser') ? this.customer : this.existing_customer,
          vehicle_details: (this.booking.customerType === 'newUser') ? this.vehicle : this.existing_vehicle,
        };
      } else {
        url = "csmsBookings/updateBooking";
        msg = this.$t("updated_successfully")
        bookingObject = {
          id: this.booking.booking_id,
          booking_date: this.booking.booking_date,
          start_slot_time: this.booking.start_slot_time,
          location_id: this.booking.location_id,
          tenant_id: this.booking.tenant_id,
          booking_status: this.booking.booking_status,
          customer_details: {
            customer_id: this.booking.customer_id,
            customer_name: this.booking.customer_name,
            customer_country_code: '+92',
            customer_phone_number: this.booking.customer_phone_number,
            customer_email: this.booking.customer_email
            ,
          },
          vehicle_details: {
            vehicle_id: this.booking.vehicle_id,
            vehicle_md_make_id: this.booking.vehicle_make_id,
            vehicle_md_model_id: this.booking.vehicle_model_id,
            vehicle_registration_no: this.booking.vehicle_reg_no,
            vehicle_year: this.booking.vehicle_year,
          }
        }
      }
      await this.$store
        .dispatch(url, bookingObject)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            msg
          );
          this.$emit('change');
        }).catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    async onMakeChange(v) {
      delete this.vehicle.vehicle_md_model_id;
      delete this.vehicle.vehicle_md_make_id;
      if (v) {
        await this.$store.dispatch("mdModels/list", {
          tenant_id: this.options.tenant_id,
          md_make_id: v ?? false,
        });
        this.vehicle.vehicle_md_make_id = v
      }
    },

    close() {
      this.$emit('onclose');
    },
    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
    dateValidation(v) {
    const d = new Date();

    if (v === "min") {
        d.setDate(d.getDate() + 1); // Simply add 1 day and let JavaScript handle month/year changes
    }

    // Format day and month with leading zeros
    const formattedDay = d.getDate().toString().padStart(2, "0");
    const formattedMonth = (d.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
    const year = d.getFullYear();

    return `${year}-${formattedMonth}-${formattedDay}`;
}
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.searchBoxwithBtn
  display: flex
  flex-wrap: wrap
  align-items: center
  :deep .v-input
    width: 70%
    margin-right: 1.75rem
  :deep button
    margin: 0 !important
.toptitle
  #backBtn
    padding: 0 !important
    position: absolute
    left: 27px
    background: #eee
    display: flex
    align-items: center
    justify-content: center
    align-items: center
    margin: 0 !important
    :deep .v-btn__content
      .v-icon
        position: relative
        top: unset
        left: unset
        right: unset
        opacity: 1
        font-size: 21px
        color: #333
        height: 29px !important
.v-application
    .bodTop
        margin-top: 1rem !important
        display: block
        padding-top: 1rem
    .addNewCard
        box-shadow: 0 0 0 !important
        border: 0 !important
        .container
            padding-top: 0
            padding-bottom: 0
            .custom-h3
                font-size: 1.25rem !important
                color: #333
                font-family: $poppinMed !important
                margin-bottom: 0.75rem !important
                display: inline-block

.userAssignWrap
  display: flex
  margin: 0
  padding: 12px
  flex-wrap: wrap
  .rowWrap
    margin: 0 !important
    width: 100% !important
  .noMargin
    margin: -12px !important

.nopadding
  padding-left: 0 !important
  padding-right: 0 !important


.connectorsRow

  display: flex
  gap:1rem
  .field
    min-width:150px !important
    max-width:165px !important


.deleteButton
  cursor: pointer
  .deleteIcon
    &:hover
      color: red
</style>